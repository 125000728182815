import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: 'What we can do for you',
    paragraph: 'Our horizon is ever expanding. We strive to vertically interate all the processes that your machined product requrires, eliminating delays from outside processors. Below you will find some of the services we currently provide. If there is a service not listed below you are interested in, feel free to contact us with your needs!'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Precision at its finest
                  </div>
                <h3 className="mt-0 mb-12">
                Production Machining
                  </h3>
                <p className="m-0">
                From large production runs to one-off jobs, we can cater to your machining needs. With our extensive Tool Making experience, we will design and build in-house custom fixtures and tooling to increase production and in turn lower your costs and lead times. 
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/Production-machining.png')}
                  alt="Features split 01"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  In house treatments
                  </div>
                <h3 className="mt-0 mb-12">
                  Post Processing
                  </h3>
                <p className="m-0">
                In addition to production machining, we offer anodizing, electroplating, heat treating, sheet metal processing, and laser engraving services in house. This means no having to factor in an outside processor’s lead times. We will ensure your product is exactly as you order it, right on time.
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/Anodize.jpg')}
                  alt="Features split 02"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Bring your ideas to life
                  </div>
                <h3 className="mt-0 mb-12">
                  Product Design Services
                  </h3>
                <p className="m-0">
                  Let us to bring your ideas to fruition! We offer 3D design and modeling services. We can take your product from a napkin sketch to a prototype to a selling product all under the same roof. With our rapid prototyping capability we can 3D print your designs to perfection, when they are ready to machine.
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/CNDR.png')}
                  alt="Features split 03"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Keep your equipment running
                  </div>
                <h3 className="mt-0 mb-12">
                  Reverse Engineering and Repair Services
                  </h3>
                <p className="m-0">
                Many small to medium businesses utilize equipment that is decades old, and that equipment will need replacement components. These can be hard to find, and sometimes buying new is not even an option. Alternately, you may be looking to make changes to the functionality or aesthetics of a part currently in service. We can breathe new life into your equipment with reverse-engineered and re-designed replacement components. 
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/turret.jpg')}
                  alt="Features split 03"
                  width={528}
                  height={396} />
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;